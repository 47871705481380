import ApplicationController from './application_controller'
import Rails from '@rails/ujs';

let throttle = require('lodash/throttle');

export default class extends ApplicationController {

	static targets = [ "mainArea", "maintenanceList", "recurringFormContainer", "recurringFormButton", "form" ]

	handleKeyup(event) {
		if ("preventSubmit" in event.currentTarget.dataset) {
			if (event.type === 'keyup' && ['Enter'].includes(event.key)) {

			    event.preventDefault()
			    event.stopImmediatePropagation()
			    console.log("preventing sumbission")
			    return false

			}
		}
	}

	toggleSibling(event) {
		if (event.target.classList.contains("skip-action")) {
			return
		}

		if (event.target.classList.contains("action-item-container")) {
			var parentElement = event.target
		} else {
			var parentElement = event.target.closest(".action-item-container")
		}

		parentElement.querySelector(".action-item-description").classList.toggle("max-h-0")
		parentElement.querySelector(".action-item-description").classList.toggle("mt-1")
	}

	toggleRecurring(event) {
		var targetClassList = this.recurringFormContainerTarget.classList
		var formInputs = this.recurringFormContainerTarget.querySelectorAll('INPUT')
		var formSelects = this.recurringFormContainerTarget.querySelectorAll('SELECT')

		if (targetClassList.contains("hidden")) {
			this.recurringFormButtonTarget.classList.add("hidden")
			targetClassList.remove("hidden")
			for (var i = 0 ; i < formInputs.length ; i++ ) {
				formInputs[i].removeAttribute("disabled");
			}
			for (var i = 0 ; i < formSelects.length ; i++ ) {
				formSelects[i].removeAttribute("disabled");
			}
		} else {
			this.recurringFormButtonTarget.classList.remove("hidden")
			targetClassList.add("hidden")
			for (var i = 0 ; i < formInputs.length ; i++ ) {
				formInputs[i].setAttribute("disabled", "true");
			}
			for (var i = 0 ; i < formSelects.length ; i++ ) {
				formSelects[i].setAttribute("disabled", "true");
			}
		}

	}

	activateFormPage(event) {
		var targetPage = event.target.dataset.showPageIndex
		var formPages = this.formTarget.querySelectorAll('.form-page')
		var [ validated, errorFields ] = this.validateRequired(event)

		if (validated) {

			for (var i = 0 ; i < formPages.length ; i++ ) {
				if ( formPages[i].dataset.pageIndex == targetPage ) {
					formPages[i].classList.remove("hidden")
				} else {
					formPages[i].classList.add("hidden")
				}
			}

		} else {
			this.showErrors(formErrors)
		}

	}

	validateRequired(event) {
		var currentPageId = "#form-page-" + event.target.dataset.currentPageIndex
		var currentPage = this.formTarget.querySelector(currentPageId)
		var requiredFields = currentPage.querySelectorAll(".required")

		var validated = true
		var errorFields = []

		return [ validated, formErrors ]
	}

	showErrors(formErrors) {

	}

	enableFields(targetElement) {	
		var formInputs = targetElement.querySelectorAll('INPUT')
		var formSelects = targetElement.querySelectorAll('SELECT')

		for (var i = 0 ; i < formInputs.length ; i++ ) {
			formInputs[i].removeAttribute("disabled");
		}
		for (var i = 0 ; i < formSelects.length ; i++ ) {
			formSelects[i].removeAttribute("disabled");
		}
	}

	disableFields(targetElement) {
		var formInputs = targetElement.querySelectorAll('INPUT')
		var formSelects = targetElement.querySelectorAll('SELECT')

		for (var i = 0 ; i < formInputs.length ; i++ ) {
			formInputs[i].setAttribute("disabled", "true");
		}
		for (var i = 0 ; i < formSelects.length ; i++ ) {
			formSelects[i].setAttribute("disabled", "true");
		}	
	}

	deleteRecurring(event) {
		// Get element of the form related to the delete button
		var formElement = event.target.closest(".nested-frequency-fields")
		// Get the delete button
		var deleteButton = formElement.querySelector('.delete-recurring')
		// Count the total number of delete buttons as a proxy for number of forms
		var recurringCount = this.recurringFormContainerTarget.querySelectorAll('.delete-recurring').length

		var savedElementId = this.checkIfSaved(formElement)

		if (savedElementId) {

			var confirmResult = confirm("Are you sure you want to delete?")

			if (confirmResult) {
				// AJAX delete
				var url = window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")) + "/pmf/" + savedElementId[0]

				Rails.ajax({
					type: 'DELETE',
					url: url,
					success: (data, status, xhr) => {
						this.recurringFormContainerTarget.querySelector(savedElementId[1]).outerHTML = ''
					}
				})
				
			} else {
				return
			}
		}

		if (recurringCount > 1) {
			formElement.outerHTML = ''
		} else {
			this.toggleRecurring(event)
		}
	}

	checkIfSaved(formElement) {
		// Get first input ID
		var elementId = formElement.querySelectorAll("INPUT")[0].id
		var hiddenId = "#" + elementId.substring(0, elementId.lastIndexOf("_")) + "_id"
		var hiddenField = this.recurringFormContainerTarget.querySelector(hiddenId)

		if (hiddenField) {
			return [hiddenField.value, hiddenId]
		} else {
			return null
		}
	}

	addAdditionalFrequency(event) {
		var recurringToggleButton = '<div class="toggle-recurring" data-action="click->maintain#deleteRecurring" data-recurring-element="[recurring-element-id]"><i class="fas fa-trash"></i></div>'
		var nestedForms = event.target.parentElement.querySelectorAll('.nested-frequency-fields')
		var lastForm = nestedForms[nestedForms.length - 1]

		var newForm = this.incrementForm(lastForm)

		lastForm.insertAdjacentHTML('afterend',newForm.outerHTML)

	}

	incrementForm(lastForm) {
		var formInputs = lastForm.querySelectorAll('INPUT')
		var targetInput = formInputs[formInputs.length - 1]

		var targetIndex = parseInt(targetInput.name.split('[')[2].replace(']',''))
		var newIndex = targetIndex + 1

		var newForm = lastForm.cloneNode(true)
		var newInputs = newForm.querySelectorAll('INPUT')
		var newSelects = newForm.querySelectorAll('SELECT')
		var newLabels = newForm.querySelectorAll('LABEL')

		var replaceNameString = "[" + targetIndex + "]"
		var newNameString = "[" + newIndex + "]"

		var replaceIdString = "_" + targetIndex + "_"
		var newIdString = "_" + newIndex + "_"

		// Adjust identifying and default attributes for INPUT fields
		for (var i = 0 ; i < newInputs.length ; i++ ) {
			newInputs[i].setAttribute("name", formInputs[i].name.replace(replaceNameString,newNameString))
			newInputs[i].setAttribute("id", formInputs[i].id.replace(replaceIdString,newIdString))
			
			// Set defaults values based on input type
			if (newInputs[i].type == "checkbox") {
				newInputs[i].setAttribute("checked", "true")
			} else if (newInputs[i].type == "number") {
				newInputs[i].setAttribute("value", "1")
			}
		}

		// Adjust identifying and default attributes for SELECT fields
		for (var i = 0 ; i < newSelects.length ; i++ ) {
			newSelects[i].setAttribute("name", newSelects[i].name.replace(replaceNameString,newNameString))
			newSelects[i].setAttribute("id", newSelects[i].id.replace(replaceIdString,newIdString))
			for (var z = 0 ; z < newSelects[i].options.length ; z++ ) {
				console.log(newSelects[i].options[z])
				newSelects[i].options[z].removeAttribute("selected")
			}

			newSelects[i].options[0].setAttribute("selected", "selected")
		}

		// Associate labels with new inputs
		for (var i = 0 ; i < newLabels.length ; i++ ) {
			newLabels[i].setAttribute("for", newLabels[i].htmlFor.replace(replaceIdString,newIdString))
		}

		// Increment recurringElement index for tracking
		var oldElementIndex = parseInt(newForm.querySelector('.delete-recurring').dataset.recurringElement)
		newForm.querySelector('.delete-recurring').dataset.recurringElement = oldElementIndex + 1

		return newForm
	}


}