import ApplicationController from './application_controller'
import Rails from '@rails/ujs';

let throttle = require('lodash/throttle');

export default class extends ApplicationController {

	static targets = [ "mobileProfileSideBar", "mainProfileSideBar", "profileMainArea", "navToggle", "searchArea", "searchInput", "mainArea" ]
	
	initialize() {
		this.searchChange = throttle(this.searchChange, 750).bind(this)
	}

	connect() {
		if(this.element.scrollWidth >= 500) {
			this.setColumns()
			this.adjustWidth()
		}
	}

	adjustWidth() {
		var activeSidebar
		if (this.mobileProfileSideBarTarget.classList.contains("hidden")) {
			activeSidebar = this.mobileProfileSideBarTarget
		} else {
			activeSidebar = this.mainProfileSideBarTarget
		}
		if (this.searchAreaTarget.dataset.showSearch == 'yes') {
			if (activeSidebar.classList.contains("-ml-64")) {
				var adjusted_width = window.innerWidth

				if (this.searchInputTarget.classList.contains("hidden")) {
					this.searchInputTarget.classList.remove("hidden", "opacity-0")
				}
			} else {
				var adjusted_width = window.innerWidth - activeSidebar.scrollWidth
				if (adjusted_width < this.searchInputTarget.scrollWidth + 140) {
					this.searchInputTarget.classList.add("hidden", "opacity-0")
				} else  {
					this.searchInputTarget.classList.remove("hidden", "opacity-0")
				}
			}
			this.searchAreaTarget.style.width = adjusted_width + "px"
		}

	}

	setColumns() {
		var dataContainer = this.profileMainAreaTarget.querySelector(".data-container")

		if (dataContainer) {
			var firstGroupsContainer = this.profileMainAreaTarget.querySelector(".profile-groups-container")
			var profileGroups  = firstGroupsContainer.querySelectorAll(".profile-group")

			if ( profileGroups.length > 1 ) {

				var secondGroupsContainer = document.createElement('div')
				secondGroupsContainer.classList.add("pl-3", "w-full", "md:w-1/2", "profile-groups-container")
				dataContainer.append(secondGroupsContainer)

				var profileGroupsArray = Array.from(profileGroups)
				var profileGroupsHeightsArray = []
				var columnA = []
				var columnB = []
				var sumA = 0
				var sumB = 0


				for (var i = 0; i < profileGroups.length; i++ ) {
					profileGroupsHeightsArray[i] = profileGroups[i].scrollHeight
				}

				var list = []

				for (var i = 0; i <profileGroupsArray.length; i++ ) {
					list.push({element: profileGroupsArray[i], height: profileGroupsHeightsArray[i]})
				}

				list.sort(function(a, b) {
				    return ((a.height > b.height) ? -1 : ((a.height == b.height) ? 0 : 1))
				})

				for (var i = 0; i < list.length; i++) {
				    profileGroupsArray[i] = list[i].element
				    profileGroupsHeightsArray[i] = list[i].height
				}

				for (var i = 0; i < profileGroupsHeightsArray.length; i++ ) {
					if (sumA > sumB) {
						columnB.push(profileGroupsArray[i])
						sumB += profileGroupsHeightsArray[i]
					} else {
						columnA.push(profileGroupsArray[i])
						sumA += profileGroupsHeightsArray[i]
					}
				}

				for (var i = 0; i < profileGroups.length; i++ ) {
					if ( columnB.includes(profileGroups[i]) ) {
						secondGroupsContainer.append(profileGroups[i])
					}
				}

				firstGroupsContainer.classList.add("md:w-1/2")
				firstGroupsContainer.classList.add("md:w-1/2")

			} else {

				var containers = dataContainer.querySelectorAll(".profile-groups-container")
				for (var i = 0; i < containers.length; i++ ) { 
					containers[i].classList.remove("md:w-1/2")
				}
			}
		}
	}

	toggleNav(event) {
		var navType = event.currentTarget.dataset.navType

		if (navType == "mobile") {
			this.mobileProfileSideBarTarget.classList.toggle("-ml-64")
			if (this.mobileProfileSideBarTarget.classList.contains("-ml-64")) {
				this.mainProfileSideBarTarget.classList.add("-ml-64")
			} else {
				this.mainProfileSideBarTarget.classList.remove("-ml-64")
			}
		} else {
			this.mainProfileSideBarTarget.classList.toggle("-ml-64")
			if (this.mainProfileSideBarTarget.classList.contains("-ml-64")) {
				this.mobileProfileSideBarTarget.classList.add("-ml-64")
			} else {
				this.mobileProfileSideBarTarget.classList.remove("-ml-64")
			}
		}

		this.adjustWidth()

	}

	changeProfile(event) {
		var guuid = event.target.value
		var url = window.location.origin + '/p/change/' + guuid
		console.log(guuid)
		console.log(url)
		window.location.replace(url);
	}

	clearNewValueForm(event) {

	    var replaceTarget = '#' + event.target.dataset.replaceTarget + "_new_profile_value_form_area"
	    var replaceTargetElement = this.mainAreaTarget.querySelector(replaceTarget)

	    if (replaceTargetElement) {
	        replaceTargetElement.outerHTML = '<div id="' + event.target.dataset.replaceTarget + '_new_area"></div>'
	    }
	}

	preventSubmit(e) {
		if (e.key == "Enter") { e.preventDefault() }
	}

	searchChange() {
		var query = this.searchInputTarget.value
		var url = this.searchInputTarget.dataset.searchUrl + "?query=" + query

		Rails.ajax({
	      type: "GET",
	      url: url,
	      dataType: 'json',
	      success: (data) => {
	        this.mainAreaTarget.innerHTML = data.results
	        this.setColumns()
	      }
	    })

	    
	}

	toggleExtraActions(event) {
		if (event.target.classList.contains("extra-actions") || event.target.classList.contains("extra-actions-child") || event.target.tagName == "path" || event.target.tagName == "svg" || event.target.tagName == "a") {
			return
		} else {
			var extraActionsParent = event.target.closest(".extra-actions-parent")
			var extraActionsTarget = extraActionsParent.querySelector(".extra-actions")
			extraActionsTarget.classList.toggle("w-0")
			extraActionsTarget.classList.toggle("w-max")
			extraActionsTarget.classList.toggle("ml-3")
		}

	}


}