// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from '@rails/ujs';
import StimulusReflex from 'stimulus_reflex'

let throttle = require('lodash/throttle');

export default class extends Controller {



}