// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "stylesheets/application"
import "@fortawesome/fontawesome-free/js/all";
// import gtag from 'src/analytics'
import 'controllers'

require("chartkick")
require("chart.js")

// Trix wants to be global
window.Trix = require("trix")
require("@rails/actiontext")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

/* what the newly created button does */
// window.Trix.config.textAttributes.fontSizeXS = {
//     style: { fontSize: "0.75rem", lineHeight: "1rem" },
//     parser: function(element) {
//         return element.style.fontSize === "0.75rem" && element.style.lineHeight === "1rem"
//     },
//     inheritable: true
// }

/* insert the button visual in the default toolbar */
// window.addEventListener("trix-initialize", function(event) {
//     var buttonHTML = '<button type="button" class="trix-button" data-trix-attribute="fontSizeXS">XS</button>'

//     event.target.toolbarElement.
//     querySelector(".trix-button-group").
//     insertAdjacentHTML("beforeend", buttonHTML)
// })


window.addEventListener('scroll', () => {
  document.body.style.setProperty('--scroll',window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
}, false);

document.addEventListener('turbolinks:load', () => {

	var contactIsHomeowner = document.getElementById('contact_is_homeowner');

	if (contactIsHomeowner) {
		contactIsHomeowner.addEventListener('change', () => {

			if (contactIsHomeowner.value == "true") {
				document.getElementById('is_first_home_container').classList.remove("hidden");
			} else {
				document.getElementById('is_first_home_container').classList.add("hidden");
			}
		})
	}

	// var adminNavClose = document.getElementById('admin-nav-close');
	// var adminNavOpen = document.getElementById('admin-nav-open');
	var adminSideNavToggle = document.getElementById('admin-side-nav-toggle');
	var adminDropNavToggle = document.getElementById('admin-drop-nav-toggle');
	var adminSideNav = document.getElementById('admin-side-nav');
	var adminDropNav = document.getElementById('admin-drop-nav');
	var adminTopBar = document.getElementById('admin-top-bar');
	var adminContent = document.getElementById('admin-content');

	if (adminSideNavToggle) {
		adminSideNavToggle.addEventListener('click', () => {
			adminSideNav.classList.toggle('side-nav-closed');
			adminContent.classList.toggle('side-nav-open');
			adminTopBar.classList.toggle('side-nav-open');
		})
	}

	if (adminDropNavToggle) {
		adminDropNavToggle.addEventListener('click', () => {
			adminDropNav.classList.toggle('drop-nav-open');
		})
	}

	
	// adminNavClose.addEventListener('click', () => {
	// 	adminSideNav.classList.add("w-0");
	// 	adminSideNav.classList.remove("w-60");
	// 	// adminSideNav.classList.remove("ml-0");

	// 	// adminContent.classList.add("pl-0");
	// 	// adminContent.classList.remove("pl-60");
	// })

	// adminNavOpen.addEventListener('click', () => {
	// 	adminSideNav.classList.toggle("w-0");
	// 	adminSideNav.classList.toggle("w-60");
	// 	// adminSideNav.classList.add("ml-0");

	// 	// adminContent.classList.add("pl-60");
	// 	// adminContent.classList.remove("pl-0");
	// })

  var currentSlide = 1;
  var carouselTimer;
  showSlide();

  function setAndShowSlide(n) {
    showSlide(currentSlide = n);
  }

  function incrementSlide(n) {
    showSlide(currentSlide += n);
  }

  function showSlide() {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");

    if (slides.length > 0) {
	    // Hide all images
	    for ( i=0; i < slides.length; i++ ) {
	      slides[i].style.display = "none";
	    }

	    // Remove active from all dots
	    for ( i = 0; i < dots.length; i++ ) {
	      dots[i].className = dots[i].className.replace(" active", "");
	    }

	    // Account for n outside slide count
	    if ( currentSlide > slides.length ) {
	      currentSlide = 1;
	    }
	    if ( currentSlide < 1 ) {
	      currentSlide = slides.length
	    }

	    // Show slide n and mark dot n as active
	    slides[currentSlide-1].style.display = "block";
	    dots[currentSlide-1].className += " active";

	    // Reset timer for next slide
	    clearTimeout(carouselTimer);
	    carouselTimer = setTimeout(() => incrementSlide(1), 5000);
	}
  }

  // COLLAPSIBLE
  	var coll = document.getElementsByClassName("collapsible");
	var i;

	for (i = 0; i < coll.length; i++) {
	  coll[i].addEventListener("click", function() {
	    this.classList.toggle("collapse-open");
		var content = this.nextElementSibling;
			content.classList.toggle("pb-3");
		    if (content.style.maxHeight){
		      content.style.maxHeight = null;
		    } else {
		      content.style.maxHeight = content.scrollHeight + "px";
		    }
		});
	}

	var collBtn = document.getElementById("collapsible-sidebar-button");
	if (collBtn) {
		collBtn.addEventListener("click", function() {
			console.log("clicked");
			var collSideBar = document.getElementById("collapsible-sidebar");
			var collTopBar = document.getElementById("collapsible-topbar");
			if (collSideBar) {
				console.log(collSideBar);
				if (collSideBar.style.width == "0px") {
					collSideBar.style.width = "200px";
				} else {
					collSideBar.style.width = "0px"
				}
			}
			if (collTopBar) {
				console.log(collTopBar);
			    if (collTopBar.style.maxHeight){
			      collTopBar.style.maxHeight = null;
			    } else {
			      collTopBar.style.maxHeight = collTopBar.scrollHeight + "px";
			    }
			}
		});
	}

	// Paint colors
	var paintNotListed = document.getElementById("paint-not-listed");
	var cancelPaintNotListed = document.getElementById("cancel-paint-not-listed");
	var existingPaintFields = document.getElementById("existing-paint-fields");
	var customPaintFields = document.getElementById("custom-paint-fields");
	// setRadioChecks();

	if (paintNotListed || cancelPaintNotListed) {
		paintNotListed.addEventListener("click", function() {
			togglePaintFields();
		});
		cancelPaintNotListed.addEventListener("click", function() {
			togglePaintFields();
		});
	}

	function togglePaintFields() {
		existingPaintFields.classList.toggle("hidden");
		customPaintFields.classList.toggle("hidden");

		if (existingPaintFields.classList.contains("hidden")) {
			var selectFields = existingPaintFields.getElementsByTagName('SELECT');
			var i;
			for (i = 0; i < selectFields.length; i++) {
				selectFields[i].options[0].selected = true
			}
		}
		if (customPaintFields.classList.contains("hidden")) {
			var inputFields = customPaintFields.getElementsByTagName('INPUT');
			var i;
			for (i = 0; i < inputFields.length; i++) {
				inputFields[i].value = ''
			}
		}
	}

	// Appliance table updates


	// setSearchListeners();

	// function setSearchListeners() {
	// 	var modelNumberQuery = document.getElementById("model_number_query");
	// 	var applianceManufacturerQuery = document.getElementById("appliance_manufacturer_id");
	// 	var applianceSearchClear = document.getElementById("appliance_search_clear");
	// 	var customProfileApplianceButtons = document.getElementsByClassName("custom_profile_appliance_button");
	// 	var appliancesSearchButtons = document.getElementsByClassName("appliances_search_button");

	// 	var colorNameQuery = document.getElementById("color_name_query");
	// 	var paintManufacturerQuery = document.getElementById("paint_manufacturer_id");
	// 	var paintColorSearchClear = document.getElementById("paint_color_search_clear");
	// 	var customProfilePaintColorButtons = document.getElementsByClassName("custom_profile_paint_color_button");
	// 	var paintColorsSearchButtons = document.getElementsByClassName("paint_colors_search_button");


	// 	var z;

	// 	for (z = 0; z < customProfileApplianceButtons.length; z++) {
	// 		customProfileApplianceButtons[z].addEventListener("click", function() {
	// 			updateApplianceSelectionArea("manual");				
	// 		});
	// 	}

	// 	for (z = 0; z < appliancesSearchButtons.length; z++) {
	// 		appliancesSearchButtons[z].addEventListener("click", function() {
	// 			updateApplianceSelectionArea("search");	
	// 		});
	// 	}

	// 	for (z = 0; z < customProfilePaintColorButtons.length; z++) {
	// 		customProfilePaintColorButtons[z].addEventListener("click", function() {
	// 			updatePaintSelectionArea("manual");				
	// 		});
	// 	}

	// 	for (z = 0; z < paintColorsSearchButtons.length; z++) {
	// 		paintColorsSearchButtons[z].addEventListener("click", function() {
	// 			updatePaintSelectionArea("search");	
	// 		});
	// 	}



	// 	if (modelNumberQuery) {
	// 		modelNumberQuery.addEventListener("change", function() {
	// 			applianceTableSearch(modelNumberQuery,applianceManufacturerQuery);
	// 		});
	// 		modelNumberQuery.addEventListener('keydown', function (e) {
	// 	  		var key = e.charCode || e.keyCode || 0;     
	// 	  		if (key == 13) {
	// 	    		applianceTableSearch(modelNumberQuery,applianceManufacturerQuery);
	// 	  		}
	// 		});
	// 	}

	// 	if (applianceManufacturerQuery) {
	// 		applianceManufacturerQuery.addEventListener("change", function() {
	// 			applianceTableSearch(modelNumberQuery,applianceManufacturerQuery);
	// 		});
	// 	}

	// 	if (colorNameQuery) {
	// 		colorNameQuery.addEventListener("change", function() {
	// 			paintColorTableSearch(colorNameQuery,paintManufacturerQuery);
	// 		});
	// 		colorNameQuery.addEventListener('keydown', function (e) {
	// 	  		var key = e.charCode || e.keyCode || 0;     
	// 	  		if (key == 13) {
	// 	    		paintColorTableSearch(colorNameQuery,paintManufacturerQuery);
	// 	  		}
	// 		});
	// 	}

	// 	if (paintManufacturerQuery) {
	// 		paintManufacturerQuery.addEventListener("change", function() {
	// 			paintColorTableSearch(colorNameQuery,paintManufacturerQuery);
	// 		});
	// 	}



	// 	if (applianceSearchClear) {
	// 		applianceSearchClear.addEventListener("click", function() {
	// 			if (modelNumberQuery) {
	// 				modelNumberQuery.value = null;
	// 			}
	// 			if (applianceManufacturerQuery) {
	// 				applianceManufacturerQuery.selectedIndex = "0";
	// 			}
	// 			applianceTableSearch(modelNumberQuery,applianceManufacturerQuery);
	// 		});		
	// 	}

	// 	if (paintColorSearchClear) {
	// 		paintColorSearchClear.addEventListener("click", function() {
	// 			if (colorNameQuery) {
	// 				colorNameQuery.value = null;
	// 			}
	// 			if (paintManufacturerQuery) {
	// 				paintManufacturerQuery.selectedIndex = "0";
	// 			}
	// 			paintColorTableSearch(colorNameQuery,paintManufacturerQuery);
	// 		});		
	// 	}

	// }

	// function setRadioChecks() {
	// 	var radioChecks = document.getElementsByClassName("radio_check");

	// 	for (i = 0; i < radioChecks.length; i++) {
	// 	  	radioChecks[i].addEventListener("click", function() {

	// 		  	var y;

	// 		  	for (y = 0; y < radioChecks.length; y++) {
	// 			  radioChecks[y].classList.remove("bg-grey");
	// 			}

	// 		    var radioCheck = this.getElementsByTagName('INPUT')[0]

	// 		    if (radioCheck.checked) {
	// 		    	radioCheck.checked = false;
	// 		    } else {
	// 		    	this.classList.add("bg-grey");
	// 		    	radioCheck.checked = true;
	// 		    }

	// 		});
	// 	}
	// }

	// function applianceTableSearch(modelNumberQuery,applianceManufacturerQuery) {

	// 	var model_number_query;
	// 	var appliance_manufacturer_id;

	// 	if (modelNumberQuery) {
	// 		model_number_query = modelNumberQuery.value;
	// 		console.log(model_number_query);
	// 	} else {
	// 		model_number_query = '';
	// 	}

	// 	if (applianceManufacturerQuery) {
	// 		appliance_manufacturer_id = applianceManufacturerQuery.value;
	// 		console.log(appliance_manufacturer_id);
	// 	} else {
	// 		appliance_manufacturer_id ='';
	// 	}

	// 	var search_query = "model_number_query=" + model_number_query + "&appliance_manufacturer_id=" + appliance_manufacturer_id;
	// 	console.log("/appliances_search?" + search_query);
	// 	Rails.ajax({
	// 		url: "/appliances_search?" + search_query,
	// 		type: "get",
	// 		success: function(data) { 
	// 			console.log(data.html);
	// 			document.getElementById("appliances_table").innerHTML = data.html;
	// 			setSearchListeners();
	// 			setRadioChecks();
	// 		}

	// 	});
	// }

	// function paintColorTableSearch(colorNameQuery,paintManufacturerQuery) {

	// 	var color_name_query;
	// 	var paint_manufacturer_id;

	// 	if (colorNameQuery) {
	// 		color_name_query = colorNameQuery.value;
	// 		console.log(color_name_query);
	// 	} else {
	// 		color_name_query = '';
	// 	}

	// 	if (paintManufacturerQuery) {
	// 		paint_manufacturer_id = paintManufacturerQuery.value;
	// 		console.log(paint_manufacturer_id);
	// 	} else {
	// 		paint_manufacturer_id ='';
	// 	}

	// 	var search_query = "color_name_query=" + color_name_query + "&paint_manufacturer_id=" + paint_manufacturer_id;
	// 	console.log("/paint_colors_search?" + search_query);
	// 	Rails.ajax({
	// 		url: "/paint_colors_search?" + search_query,
	// 		type: "get",
	// 		success: function(data) { 
	// 			console.log(data.html);
	// 			document.getElementById("paint_colors_table").innerHTML = data.html;
	// 			setSearchListeners();
	// 			setRadioChecks();
	// 		}

	// 	});
	// }

	// function updateApplianceSelectionArea(entry_type) {
	// 	var html_retrieve_url;

	// 	if (entry_type == "search") {
	// 		html_retrieve_url = "/appliances_search_partial"
	// 	} else {
	// 		html_retrieve_url = "/appliances_manual_partial"
	// 	}
	// 	Rails.ajax({
	// 		url: html_retrieve_url,
	// 		type: "get",
	// 		success: function(data) {
	// 			console.log(data.html);
	// 			document.getElementById("appliance_selection_area").innerHTML = data.html;
	// 			setSearchListeners();
	// 			setRadioChecks();
	// 		}
	// 	});
	// }

	// function updatePaintSelectionArea(entry_type) {
	// 	var html_retrieve_url;

	// 	if (entry_type == "search") {
	// 		html_retrieve_url = "/paint_colors_search_partial"
	// 	} else {
	// 		html_retrieve_url = "/paint_colors_manual_partial"
	// 	}
	// 	Rails.ajax({
	// 		url: html_retrieve_url,
	// 		type: "get",
	// 		success: function(data) {
	// 			console.log(data.html);
	// 			document.getElementById("paint_color_selection_area").innerHTML = data.html;
	// 			setSearchListeners();
	// 			setRadioChecks();
	// 		}
	// 	});
	// }

	// // Stop ENTER from submitting form (due to search)
	// var newProfileApplianceForm = document.getElementById("new_profile_appliance_form");
	// var newProfilePaintColorForm = document.getElementById("new_profile_paint_color_form");

	// if (newProfileApplianceForm) {
	// 	newProfileApplianceForm.onkeypress = function(e) {
	// 	  var key = e.charCode || e.keyCode || 0;     
	// 	  if (key == 13) {
	// 	    e.preventDefault();
	// 	  }
	// 	}
	// }

	// if (newProfilePaintColorForm) {
	// 	newProfilePaintColorForm.onkeypress = function(e) {
	// 	  var key = e.charCode || e.keyCode || 0;     
	// 	  if (key == 13) {
	// 	    e.preventDefault();
	// 	  }
	// 	}
	// }

	// var test_worker_button = document.getElementById('test-worker-button');
	// if (test_worker_button) {
	// 	test_worker_button.addEventListener('click', function() {
	// 		Rails.ajax({
	// 			url: '/test_worker',
	// 			type: "get",
	// 			success: function() {
	// 				console.log("Email should have sent");
	// 			}
	// 		});
	// 	});
	// }


});


require("trix")
require("@rails/actiontext")