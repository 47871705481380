import ApplicationController from './application_controller'
import Rails from '@rails/ujs';

let throttle = require('lodash/throttle');

export default class extends ApplicationController {

	static targets = [ "mainArea", "subscriptionOptionsArea", "checkCodeArea" ]

	updateSubscriptionOptions(event) {
		let [data, status, xhr] = event.detail

		this.subscriptionOptionsAreaTarget.outerHTML = data.subscriptionOptions
		this.checkCodeAreaTarget.outerHTML = data.checkCodeArea
		console.log("updateSubscriptionOptions")
		console.log(data)
		console.log(status)
		console.log(xhr)
	}

	updateCheckCode(event) {
		let [data, status, xhr] = event.detail

		this.checkCodeAreaTarget.outerHTML = xhr.response
		console.log("updateCheckCode")
		console.log(data)
		console.log(status)
		console.log(xhr)
	}

}