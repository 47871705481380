import { Controller } from "stimulus"
import Rails from '@rails/ujs';

let throttle = require('lodash/throttle');

export default class extends Controller {

	static targets = [ "profileSurvey", "surveyQuestion", "answerArea", "continueButton", "skipButton" ]
	
	initialize() {
		this.toggleSubmit = throttle(this.toggleSubmit, 750).bind(this)
	}

	toggleRadio(event) {
		var questionOptions = this.answerAreaTarget.querySelectorAll(".option-area")
		for (var i=0; i<questionOptions.length; i++ ) {
			questionOptions[i].classList.remove("bg-primary-100", "bg-opacity-20", "text-primary-action", "border-primary-action")
			questionOptions[i].classList.add("text-grey-textalt", "border-grey-textalt")
		}
		
		if (event.target.previousElementSibling.checked) {
			event.target.previousElementSibling.checked = false
			this.continueButtonTarget.classList.add("hidden")
		} else {
			event.target.classList.add("bg-primary-100", "bg-opacity-20", "text-primary-action", "border-primary-action")
			event.target.classList.remove("text-grey-textalt", "border-grey-textalt")
			event.target.previousElementSibling.checked = true
			this.continueButtonTarget.classList.remove("hidden")
		}
	}

	toggleCheckBox(event) {
		var questionOptions = this.answerAreaTarget.querySelectorAll("INPUT")
		var answered_count = 0 // checkbox toggles after this, so offsetting by 1
		for (var i=0; i<questionOptions.length; i++ ) {
			if (questionOptions[i].checked) {
				answered_count += 1
			}
		}

		if (event.target.classList.contains("bg-primary-100")) {
			event.target.classList.remove("bg-primary-100", "bg-opacity-20", "text-primary-action", "border-primary-action")
			event.target.classList.add("text-grey-textalt", "border-grey-textalt")
			answered_count -= 1

		} else {
			event.target.classList.add("bg-primary-100", "bg-opacity-20", "text-primary-action", "border-primary-action")
			event.target.classList.remove("text-grey-textalt", "border-grey-textalt")
			answered_count += 1
		}

		if (answered_count > 0) {
			this.continueButtonTarget.classList.remove("hidden")
			if (this.continueButtonTarget.dataset.answered == "false") {
				this.skipButtonTarget.classList.add("hidden")
			}

		} else {
			this.continueButtonTarget.classList.add("hidden")
			if (this.continueButtonTarget.dataset.answered == "false") {
				this.skipButtonTarget.classList.remove("hidden")
			}
		}

	}

	toggleSubmit(event) {
		console.log(event.target.value)
		if (event.target.value != '') {
			this.continueButtonTarget.classList.remove("hidden")
		} else {
			this.continueButtonTarget.classList.add("hidden")
		}
	}

	updateElement(event) {

		var replaceTarget = '#' + event.target.dataset.replaceTarget
		var replaceTargetElement = this.profileSurveyTarget.querySelector(replaceTarget)
		let [data, status, xhr] = event.detail;

		console.log(replaceTargetElement)
		console.log(xhr.response)

		if (replaceTargetElement) {
			replaceTargetElement.outerHTML = xhr.response

			const focusElement = this.element.querySelector('[autofocus]')
	    	if (focusElement) {
	      		focusElement.focus()

		      	// shenanigans to ensure that the cursor is placed at the end of the existing value
		      	const value = focusElement.value
		      	focusElement.value = ''
		      	focusElement.value = value
	    	}
    	}
	}


}