import ApplicationController from './application_controller'
import Rails from '@rails/ujs';

let throttle = require('lodash/throttle');

export default class extends ApplicationController {

	static targets = [ "mainArea" ]

	handleKeyup(event) {
		if ("preventSubmit" in event.currentTarget.dataset) {
			if (event.type === 'keyup' && ['Enter'].includes(event.key)) {

			    event.preventDefault()
			    event.stopImmediatePropagation()
			    console.log("preventing sumbission")
			    return false

			}
		}
	}


}