import { Controller } from "stimulus"
import Rails from '@rails/ujs';

let throttle = require('lodash/throttle');

export default class extends Controller {

	static targets = [ "maintenanceListArea", "maintenanceList", "recurringFormContainer", "recurringFormButton" ]

	handleKeyup(event) {
		if ("preventSubmit" in event.currentTarget.dataset) {
			if (event.type === 'keyup' && ['Enter'].includes(event.key)) {

			    event.preventDefault()
			    event.stopImmediatePropagation()
			    console.log("preventing sumbission")
			    return false

			}
		}
	}

	toggleSibling(event) {
		if (event.target.classList.contains("skip-action")) {
			return
		}

		if (event.target.classList.contains("action-item-container")) {
			var parentElement = event.target
		} else {
			var parentElement = event.target.closest(".action-item-container")
		}

		parentElement.querySelector(".action-item-description").classList.toggle("max-h-0")
		parentElement.querySelector(".action-item-description").classList.toggle("mt-1")
	}

	toggleRecurring(event) {
		var targetClassList = this.recurringFormContainerTarget.classList
		var formInputs = this.recurringFormContainerTarget.querySelectorAll('INPUT')
		var formSelects = this.recurringFormContainerTarget.querySelectorAll('SELECT')

		if (targetClassList.contains("hidden")) {
			this.recurringFormButtonTarget.classList.add("hidden")
			targetClassList.remove("hidden")
			for (var i = 0 ; i < formInputs.length ; i++ ) {
				formInputs[i].removeAttribute("disabled");
			}
			for (var i = 0 ; i < formSelects.length ; i++ ) {
				formSelects[i].removeAttribute("disabled");
			}
		} else {
			this.recurringFormButtonTarget.classList.remove("hidden")
			targetClassList.add("hidden")
			for (var i = 0 ; i < formInputs.length ; i++ ) {
				formInputs[i].setAttribute("disabled", "true");
			}
			for (var i = 0 ; i < formSelects.length ; i++ ) {
				formSelects[i].setAttribute("disabled", "true");
			}
		}

	}

	deleteRecurring(event) {
		var formElement = event.target.closest(".nested-frequency-fields")
		var toggleRecurring = formElement.querySelector('.toggle-recurring')

		if ( toggleRecurring && toggleRecurring.dataset.recurringElement == 1 ) {
			console.log("toggling")
			this.toggleRecurring(event)

		} else if ( toggleRecurring && toggleRecurring.dataset.recurringElement > 1 ) {
			console.log("removing")
			formElement.outerHTML = ''

		} else {
			console.log(formElement)
			var formElementIdField = formElement.nextElementSibling
			var formElementId = formElementIdField.value
			console.log(formElementId)
		}


	}

	addAdditionalFrequency(event) {
		var recurringToggleButton = '<div class="toggle-recurring" data-action="click->profile-maintenances#deleteRecurring" data-recurring-element="[recurring-element-id]"><i class="fas fa-trash"></i></div>'
		var nestedForms = event.target.parentElement.querySelectorAll('.nested-frequency-fields')
		var lastForm = nestedForms[nestedForms.length - 1]
		var formInputs = lastForm.querySelectorAll('INPUT')
		var targetInput = formInputs[formInputs.length - 1]
		var targetIndex = parseInt(targetInput.name.split('[')[2].replace(']',''))
		var newIndex = targetIndex + 1

		var newForm = lastForm.cloneNode(true)
		var newInputs = newForm.querySelectorAll('INPUT')
		var newSelects = newForm.querySelectorAll('SELECT')
		var newLabels = newForm.querySelectorAll('LABEL')
		var replaceNameString = "[" + targetIndex + "]"
		var newNameString = "[" + newIndex + "]"
		var replaceIdString = "_" + targetIndex + "_"
		var newIdString = "_" + newIndex + "_"

		for (var i = 0 ; i < newInputs.length ; i++ ) {
			newInputs[i].setAttribute("name", formInputs[i].name.replace(replaceNameString,newNameString))
			newInputs[i].setAttribute("id", formInputs[i].id.replace(replaceIdString,newIdString))
			
			if (newInputs[i].type == "checkbox") {
				newInputs[i].setAttribute("checked", "true")
			} else if (newInputs[i].type == "number") {
				newInputs[i].setAttribute("value", "1")
			}
		}
		for (var i = 0 ; i < newSelects.length ; i++ ) {
			newSelects[i].setAttribute("name", newSelects[i].name.replace(replaceNameString,newNameString))
			newSelects[i].setAttribute("id", newSelects[i].id.replace(replaceIdString,newIdString))
			for (var z = 0 ; z < newSelects[i].options.length ; z++ ) {
				console.log(newSelects[i].options[z])
				newSelects[i].options[z].removeAttribute("selected")
			}

			newSelects[i].options[0].setAttribute("selected", "selected")
		}
		for (var i = 0 ; i < newLabels.length ; i++ ) {
			newLabels[i].setAttribute("for", newLabels[i].htmlFor.replace(replaceIdString,newIdString))
		}

		if (newForm.querySelector('.toggle-recurring')) {
			var oldElementIndex = parseInt(newForm.querySelector('.toggle-recurring').dataset.recurringElement)
			newForm.querySelector('.toggle-recurring').dataset.recurringElement = oldElementIndex + 1
			lastForm.insertAdjacentHTML('afterend',newForm.outerHTML)
		} else {
			newForm.querySelector('.delete-recurring').outerHTML = '<div class="toggle-recurring" data-action="click->profile-maintenances#deleteRecurring" data-recurring-element="2"><i class="fas fa-trash"></i></div>'
			lastForm.nextElementSibling.insertAdjacentHTML('afterend',newForm.outerHTML)
		}

	}

	updateElement(event) {

		var replaceTarget = '#' + event.target.dataset.replaceTarget
		var replaceTargetElement = this.maintenanceListAreaTarget.querySelector(replaceTarget)
		let [data, status, xhr] = event.detail;

		if (replaceTargetElement) {
			replaceTargetElement.outerHTML = xhr.response

			const focusElement = this.element.querySelector('[autofocus]')
	    	if (focusElement) {
	      		focusElement.focus()

		      	// shenanigans to ensure that the cursor is placed at the end of the existing value
		      	const value = focusElement.value
		      	focusElement.value = ''
		      	focusElement.value = value
	    	}
    	}
	}

	updateSelf(event) {
		let [data, status, xhr] = event.detail
		event.currentTarget.outerHTML = xhr.response

		const focusElement = this.element.querySelector('[autofocus]')
    	if (focusElement) {
      		focusElement.focus()

	      	// shenanigans to ensure that the cursor is placed at the end of the existing value
	      	const value = focusElement.value
	      	focusElement.value = ''
	      	focusElement.value = value
    	}
	}


}