import ApplicationController from './application_controller'
import Rails from '@rails/ujs';

let throttle = require('lodash/throttle');

export default class extends ApplicationController {

	static targets = [ "mainArea", "todoList", "recurringFormContainer", "recurringFormButton", "form" ]

	handleKeyup(event) {
		if ("preventSubmit" in event.currentTarget.dataset) {
			if (event.type === 'keyup' && ['Enter'].includes(event.key)) {

			    event.preventDefault()
			    event.stopImmediatePropagation()
			    console.log("preventing sumbission")
			    return false

			}
		}
	}

	toggleSibling(event) {
		if (event.target.classList.contains("skip-action")) {
			return
		}

		if (event.target.classList.contains("action-item-container")) {
			var parentElement = event.target
		} else {
			var parentElement = event.target.closest(".action-item-container")
		}

		parentElement.querySelector(".action-item-description").classList.toggle("max-h-0")
		parentElement.querySelector(".action-item-description").classList.toggle("mt-1")
	}


}