// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "entries", "pagination" ]

  scroll() {
  	let next_page = this.paginationTarget.querySelector("a[rel='next']")
  	if (next_page == null) { return }
  		
  	let url = next_page.href

  	var scrollPosition = this.entriesTarget.scrollTop + this.entriesTarget.offsetHeight

  	if (scrollPosition >= this.entriesTarget.scrollHeight) {
  		this.loadMore(url)
  	}

  }

  loadMore(url) {
  	Rails.ajax({
  		type: 'GET',
  		url: url,
  		dataType: 'json',
  		success: (data) => {
  			this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
  			this.paginationTarget.innerHTML = data.pagination
  		}
  	})
  }
}