import ApplicationController from './application_controller'
import Rails from '@rails/ujs';

let throttle = require('lodash/throttle');

export default class extends ApplicationController {

	static targets = [ "mainArea", "resetNotificationArea", "avatarField", "avatarForm", "legalModalArea", "legalModalForm"]

	resetNotificationSuccess(event) {
		let [data, status, xhr] = event.detail

		var notificationElement = document.createElement('div')
		var notificationString = "Password reset instructions have been sent to " + xhr.response
		notificationElement.innerHTML = notificationString
		this.resetNotificationAreaTarget.appendChild(notificationElement)
	}

	resetNotificationError(event) {
		var notificationElement = document.createElement('div')
		var notificationString = "There was an error sending reset instructions. Please try again later."
		notificationElement.innerHTML = notificationString
		this.resetNotificationAreaTarget.appendChild(notificationElement)
	}

	submitAvatar(event) {
		var form = this.avatarFormTarget
		// Rails.fire(form, "submit")
		form.submit()
	}

	acceptLegal(event) {
		var form = event.currentTarget
		form.submit()
	}

	legalModalClose(event) {
		var modalTarget = '#' + event.target.dataset.modalTarget
    	var modalTargetElement = document.querySelector(modalTarget)
		modalTargetElement.classList.add('hidden')
	}

	legalModalError(event) {
		let [data, status, xhr] = event.detail

		console.log("legalModalError")
		console.log(data)
		console.log(status)
		console.log(xhr)
	}

	profileInvitationFormSuccess(event) {
		let [data, status, xhr] = event.detail
	}

	profileInvitationFormError(event) {
		let [data, status, xhr] = event.detail	
	}

	displayError(event) {

		var replaceTarget = '#' + event.target.dataset.errorTarget
	    var replaceTargetElement = this.mainAreaTarget.querySelector(replaceTarget)
	    let [data, status, xhr] = event.detail
	    replaceTargetElement.innerHTML = xhr.response

	}

	update(event) {
		var form = event.currentTarget
		form.submit()
	}


}