// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from '@rails/ujs';

let throttle = require('lodash/throttle');

export default class extends Controller {
  static targets = [ "entries", "pagination", "model", "enteredModelNumber", "enteredTypeId", "enteredManufacturerId", "manufacturer", "searchEntryArea", "manualEntryArea" ]

  initialize() {
    this.searchChange = throttle(this.searchChange, 750).bind(this)
  }

  preventSubmit(e) {
    if (e.key == "Enter") { e.preventDefault() }
  }

  scroll() {
  	let next_page = this.paginationTarget.querySelector("a[rel='next']")
  	if (next_page == null) { return }
  		
  	let url = next_page.href

  	var scrollPosition = this.entriesTarget.scrollTop + this.entriesTarget.offsetHeight

  	if (scrollPosition >= this.entriesTarget.scrollHeight) {
  		this.loadMore(url)
  	}

  }

  manualEntry() {
  	this.switchEntry("manual")
  }

  searchEntry() {
  	this.switchEntry("search")
  }

  switchEntry(entry_type) {

  	if (entry_type == "search") {
      this.manualEntryClear()
  		// this.manualEntryAreaTarget.innerHTML = ""
  		this.manualEntryAreaTarget.classList.add('hidden')
  		this.searchEntryAreaTarget.classList.remove('hidden')

  	// } else {
  	// 	Rails.ajax({
  	// 		url: "/appliances_manual_partial",
  	// 		type: 'GET',
  	// 		success: (data) => {
  	// 			this.searchClear()
  	// 			this.searchEntryAreaTarget.classList.add('hidden')
  	// 			this.manualEntryAreaTarget.innerHTML = data.html
  	// 			this.manualEntryAreaTarget.classList.remove('hidden')
  	// 		}
  	// 	})
  	// }
    } else {
      this.searchClear()
      this.searchEntryAreaTarget.classList.add('hidden')
      this.manualEntryAreaTarget.classList.remove('hidden')	
    }
  }

  checkRadio(e) {
  	var greyRadioRows = document.querySelectorAll('.radio_check.bg-grey-200')
  	var radioRow
  	var i

  	for (i = 0; i < greyRadioRows.length; i++) {
  		greyRadioRows[i].classList.toggle('bg-grey-200')
  		greyRadioRows[i].querySelector('INPUT').checked = false
  	}

  	if (e.target.classList.contains('radio_check')) {
  		radioRow = e.target
  	} else {
  		radioRow = e.target.parentNode
  	}

  	radioRow.classList.toggle('bg-grey-200')
  	radioRow.querySelector('INPUT').checked = true

  }

  searchClear(refreshResults = true) {
  	this.modelTarget.value = ""
  	this.manufacturerTarget.value = ""
  	if (refreshResults) {
  		this.searchChange()
  	}
  }

  manualEntryClear() {
    this.enteredModelNumberTarget.value = ""
    this.enteredTypeIdTarget.value = ""
    this.enteredManufacturerIdTarget.value = ""
  }

  searchChange() {
  	var modelValue = this.modelTarget.value
  	var manufacturerValue = this.manufacturerTarget.value
  	var url = this.data.get('search-url') + "?model_number_query=" + modelValue + "&appliance_manufacturer_query=" + manufacturerValue
  	this.loadMore(url,true)
  }

  loadMore(url, replaceAll = false) {

  	Rails.ajax({
  		type: 'GET',
  		url: url,
  		dataType: 'json',
  		success: (data) => {
  			if (replaceAll) {
  				this.entriesTarget.innerHTML = data.entries
  			} else {
  				this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
  			}
  			
  			this.paginationTarget.innerHTML = data.pagination
  		}
  	})
  }
}