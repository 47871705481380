import { Controller } from 'stimulus'

/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect () {
    
  }

  toggleHidden(event) {
    // Toggles the 'hidden' class on all elements with id listed
    // in the data-toggle-target attribute of the event target
    // Separate target ids with spaces
    // use 'self' to hide the event target
    var toggleTargets = event.target.dataset.toggleTarget.split(" ")
    var toggleTarget, toggleElement

    for (var i = 0; i < toggleTargets.length; i++) {

      if (toggleTargets[i] == "self") {
        toggleElement = event.target
      } else {
        toggleTarget = '#' + toggleTargets[i]
        toggleElement = this.mainAreaTarget.querySelector(toggleTarget)
      }

      toggleElement.classList.toggle('hidden')
    }
   
  }

  updateElement(event) {

    var replaceTarget = '#' + event.target.dataset.replaceTarget
    var replaceTargetElement = this.mainAreaTarget.querySelector(replaceTarget)
    let [data, status, xhr] = event.detail;

    if (replaceTargetElement) {
      var openElementIds = this.findOpenContainers(replaceTargetElement)

      replaceTargetElement.outerHTML = xhr.response

      if (openElementIds.length > 0) {
        this.openContainers(openElementIds)
      }

      const focusElement = this.element.querySelector('[autofocus]')
        if (focusElement) {
            focusElement.focus()

            // shenanigans to ensure that the cursor is placed at the end of the existing value
            const value = focusElement.value
            focusElement.value = ''
            focusElement.value = value
        }
      }
  }

  findOpenContainers(replaceTargetElement) {
    var potentialOpenElements = replaceTargetElement.querySelectorAll(".action-item-container")
    var openElementIds = []

    for (var i = 0; i < potentialOpenElements.length; i++) {
      if (!potentialOpenElements[i].querySelector(".action-item-description").classList.contains("max-h-0")) {
        openElementIds.push(potentialOpenElements[i].id)
      }
    }

    return openElementIds
  }

  openContainers(openElementIds) {
    for (var i = 0; i < openElementIds.length; i++) {
      if (openElementIds[i]) { 
        let targetElement = document.getElementById(openElementIds[i])
        if (targetElement) {
          let targetElementDescription = targetElement.querySelector(".action-item-description")
          if (targetElementDescription) {
            targetElementDescription.classList.toggle("max-h-0")
            targetElementDescription.classList.toggle("mt-1")
          }
        }
      }
    }
  }

  updateSelf(event) {
    let [data, status, xhr] = event.detail
    event.currentTarget.outerHTML = xhr.response

    const focusElement = this.element.querySelector('[autofocus]')
      if (focusElement) {
          focusElement.focus()

          // shenanigans to ensure that the cursor is placed at the end of the existing value
          const value = focusElement.value
          focusElement.value = ''
          focusElement.value = value
      }
  }

  clearElement(event) {

    var replaceTarget = '#' + event.target.dataset.replaceTarget
    var replaceTargetElement = this.mainAreaTarget.querySelector(replaceTarget)
    console.log(replaceTargetElement)
    if (replaceTargetElement) {
        replaceTargetElement.innerHTML = ""
      }
  }

  toggleSibling(event) {
    console.log(event)
    if (event.target.closest(".skip-action")) {
      return
    } else {
      if (event.target.classList.contains("action-item-container")) {
        var parentElement = event.target
      } else {
        var parentElement = event.target.closest(".action-item-container")
      }

      parentElement.querySelector(".action-item-description").classList.toggle("max-h-0")
      parentElement.querySelector(".action-item-description").classList.toggle("mt-1")
    }

  }

  formatPhoneEntry(event) {
    var input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
    var key = event.keyCode;

    // only allow numbers, delete, backspace, or enter
    if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105) || (key === 8 || key === 13 || key === 46)) {
      var areaCode = input.substring(0,3);
      var middle = input.substring(3,6);
      var last = input.substring(6,10);

      if (input.length > 6) {
        event.target.value = `(${areaCode}) ${middle}-${last}`
      } else if (input.length > 3) {
        event.target.value = `(${areaCode}) ${middle}`
      } else if (input.length > 0) {
        event.target.value = `(${areaCode}`
      }
    } else {
      event.preventDefault()
    }
  }
}
