import ApplicationController from './application_controller'
import Rails from '@rails/ujs';

let throttle = require('lodash/throttle');

export default class extends ApplicationController {

	static targets = [ "searchInput", "searchResultsArea" ]
	
	initialize() {
		this.searchChange = throttle(this.searchChange, 750).bind(this)
	}

	preventSubmit(e) {
		if (e.key == "Enter") { e.preventDefault() }
	}

	searchChange() {
		console.log("change")
		var query = this.searchInputTarget.value
		var url = this.searchInputTarget.dataset.searchUrl + "?query=" + query

		Rails.ajax({
	      type: "GET",
	      url: url,
	      dataType: 'json',
	      success: (data) => {
	        this.searchResultsAreaTarget.innerHTML = data.results
	        this.setColumns()
	      }
	    })

	    
	}


}