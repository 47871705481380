import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {

  const profile_element = document.getElementById('profile-container');

  if (profile_element) {

    const profile_guuid = profile_element.getAttribute('data-profile-guuid');

    consumer.subscriptions.create({ channel: "ProfileChannel", profile_guuid: profile_guuid }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        let html;

        const targetContainer = document.getElementById(data.html_container)
        targetContainer.outerHTML = data.html
      }
    });

  }



})
