import { Controller } from "stimulus"
import Rails from '@rails/ujs';

let throttle = require('lodash/throttle');

export default class extends Controller {

	static targets = [ "survey", "surveyGroupsList", "surveyGroupName" ]

	startSpinner(event) {
		var spinnerHTML = '<div><svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-charcoal" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg></div>'
		var spinnerTarget = '#' + event.currentTarget.dataset.spinnerTarget
		var spinnerTargetElement = this.surveyTarget.querySelector(spinnerTarget)
		console.log(spinnerTarget)
		console.log(spinnerTargetElement)
		spinnerTargetElement.outerHTML = spinnerHTML
	}

	cancelEdit(event) {
		if (event.type === 'keyup' && !['Escape', 'Esc'].includes(event.key)) return

		var replaceTarget = '#' + event.currentTarget.dataset.replaceTarget
		var replaceTargetElement = this.surveyTarget.querySelector(replaceTarget)
		const [recordId, recordType] = this.getRecordElements(event.currentTarget.dataset.replaceTarget)

		// add a data item to halt the submitForm action
		event.currentTarget.dataset.notice = "cancelled"
	
		Rails.ajax({
			type: 'GET',
			url: "/admin/" + recordType + "s/" + recordId + '/element',
			success: (data, status, xhr) => {
				replaceTargetElement.outerHTML = xhr.response
			}
		})

		
	}

	cancelNew(event) {
		if ("preventSubmit" in event.currentTarget.dataset) {
			if (event.type === 'keyup' && ['Enter'].includes(event.key)) {
				console.log("preventing sumbission")
			    event.preventDefault()
			}
		}

		if ("submitOnEnter" in event.currentTarget.dataset) {
			if (event.type === 'keyup' && ['Enter'].includes(event.key)) {
				this.submitForm(event)
			}
		}

		if (event.type === 'keyup' && !['Escape', 'Esc'].includes(event.key)) return
		var replaceTarget = '#' + event.currentTarget.dataset.replaceTarget
		var replaceTargetElement = event.currentTarget
		const [recordId, recordType] = this.getRecordElements(event.currentTarget.dataset.replaceTarget)

		// add a data item to halt the submitForm action
		event.currentTarget.dataset.notice = "cancelled"
	
		Rails.ajax({
			type: 'GET',
			url: "/admin/" + recordType + "s/" + recordId + '/cancel_new',
			success: (data, status, xhr) => {
				replaceTargetElement.outerHTML = xhr.response
			}
		})
		
	}

	cancelForm(event) {
		if ("preventSubmit" in event.currentTarget.dataset) {
			if (event.type === 'keyup' && ['Enter'].includes(event.key)) {
			    event.preventDefault()
			}
		}

		if (event.type === 'keyup' && !['Escape', 'Esc'].includes(event.key)) return
		var replaceTarget = '#' + event.currentTarget.dataset.replaceTarget
		var replaceTargetElement = this.surveyTarget.querySelector(replaceTarget)
		const [recordId, recordType] = this.getRecordElements(event.currentTarget.dataset.replaceTarget)

		// add a data item to halt the submitForm action
		event.currentTarget.dataset.notice = "cancelled"
	
		Rails.ajax({
			type: 'GET',
			url: "/admin/" + recordType + "s/" + recordId + '/cancel_form',
			success: (data, status, xhr) => {
				replaceTargetElement.outerHTML = xhr.response
			}
		})
		
	}

	submitForm(event) {
		if (event.currentTarget.dataset.notice === 'cancelled' || event.currentTarget.dataset.notice === 'submitted' ) return
			console.log("submitting")
		Rails.fire(event.currentTarget, "submit")
	}

	updateElement(event) {
		var replaceTarget = '#' + event.target.dataset.replaceTarget
		var replaceTargetElement = this.surveyTarget.querySelector(replaceTarget)
		let [data, status, xhr] = event.detail;

		if (replaceTargetElement) {
			replaceTargetElement.outerHTML = xhr.response

			const focusElement = this.element.querySelector('[autofocus]')
	    	if (focusElement) {
	      		focusElement.focus()

		      	// shenanigans to ensure that the cursor is placed at the end of the existing value
		      	const value = focusElement.value
		      	focusElement.value = ''
		      	focusElement.value = value
	    	}
    	}
	}

	updateSelf(event) {
		let [data, status, xhr] = event.detail
		event.currentTarget.outerHTML = xhr.response

		const focusElement = this.element.querySelector('[autofocus]')
    	if (focusElement) {
      		focusElement.focus()

	      	// shenanigans to ensure that the cursor is placed at the end of the existing value
	      	const value = focusElement.value
	      	focusElement.value = ''
	      	focusElement.value = value
    	}
	}

	updateForm(event) {
		if ("skipChange" in event.target.dataset) return

		var updateTarget = '#' + event.currentTarget.dataset.updateTarget
		var updateTargetElement = this.surveyTarget.querySelector(updateTarget)
		var formId = '#' + event.currentTarget.id
		var formElement = this.surveyTarget.querySelector(formId)
		var params = []
		var formData = new FormData(formElement)

		for (var [key, value] of formData.entries()) {
			var startBracketIndex = key.indexOf("[")
			var endBrackIndex = key.indexOf("]")
			if (startBracketIndex >= 0 && endBrackIndex >= 0) {
				params.push(key.substring(startBracketIndex+1,endBrackIndex) + "=" + value )
			}
		}

		console.log(params.join("&"))
		console.log(event.currentTarget.dataset.updateTarget)

		var pathComponents = event.currentTarget.dataset.updateTarget.split("_form_")
		var url = "/admin/"

		for (var i = 0; i < pathComponents.length; i++) {
			const [recordId, recordType] = this.getRecordElements(pathComponents[i])

			if (recordId == "new") {
				url = url + recordType + "s/new_"
			} else {
				url = url + recordType + "s/" + recordId + "/"
			}

		}

		url = url + 'form'

		console.log(url)

		Rails.ajax({
			type: 'GET',
			url: url,
			data: params.join("&"),
			success: (data, status, xhr) => {
				updateTargetElement.outerHTML = xhr.response
			}
		})

	}

	multipleSelect(event) {
		var updateTarget = '#' + event.target.dataset.updateTarget
		var updateTargetField = updateTarget + "_select_check_box"
		var updateTargetElement = this.surveyTarget.querySelector(updateTarget)

		if (updateTargetElement) {
		var updateTargetFieldElement = updateTargetElement.querySelector(updateTargetField)
		
			if (event.target.value == "multiple_choice") {
				updateTargetElement.classList.remove("hidden")
				updateTargetFieldElement.disabled = false
			} else {
				updateTargetElement.classList.add("hidden")
				updateTargetFieldElement.disabled = true
			}
		}
	}

	doNothing(event) {
		console.log("Doing nothing.")
	}

	clearInner(event) {
		var replaceTarget = '#' + event.target.dataset.replaceTarget
		var replaceTargetElement = this.surveyTarget.querySelector(replaceTarget)
		replaceTargetElement.innerHTML = ''
	}

	getRecordElements(record_string) {
		var idArray = record_string.split("_")
		var recordId
		var recordType

		if (idArray[idArray.length-1] == "new") {
			recordId = "new"
			recordType = idArray.slice(0,-1).join("_")
		} else {
			recordId = [idArray[idArray.length-2],idArray[idArray.length-1]].join("_")
			recordType = idArray.slice(0,-2).join("_")
		}

	    // return values
	    return [ recordId, recordType ]	
	}

}