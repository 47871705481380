import ApplicationController from './application_controller'
import Rails from '@rails/ujs';

let throttle = require('lodash/throttle');

export default class extends ApplicationController {

	static targets = [ "mainArea" ]

	handleKeyup(event) {
		if ("preventSubmit" in event.currentTarget.dataset) {
			if (event.type === 'keyup' && ['Enter'].includes(event.key)) {

			    event.preventDefault()
			    event.stopImmediatePropagation()
			    console.log("preventing sumbission")
			    return false

			}
		}
	}

	clearNewValueForm(event) {

	    var replaceTarget = '#' + event.target.dataset.replaceTarget + "_profile_file_form_area"
	    var replaceTargetElement = this.mainAreaTarget.querySelector(replaceTarget)

	    if (replaceTargetElement) {
	        replaceTargetElement.outerHTML = '<div id="profile_file_' + event.target.dataset.replaceTarget + '_area"></div>'
	    }
	}


}